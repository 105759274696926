.pageTitle {
  @apply text-4xl text-gray-800 font-bold text-center mb-10;
}

.heroSection {
  @apply w-full relative mb-10;
}

.featuredContent p {
  @apply text-left;
}

@media screen(sm) {
  .heroSection {
    @apply w-full relative mb-10;
  }
}

.heroSectionImage {
  @apply hidden w-full bg-cover sm:block;
  filter: blur(1px);
}

.contentBox {
  @apply w-full sm:w-2/6 flex flex-col justify-start items-start p-5 sm:p-10 relative sm:absolute sm:top-32 sm:left-36 bg-white sm:rounded-lg z-0 border-b-2 border-solid border-gray-200 sm:border-b-0;
}

.contentBox p {
  @apply text-base sm:text-lg;
}

.boxTitle {
  @apply w-full text-2xl sm:text-3xl text-zur-orange-100 text-left mb-3 sm:mb-5;
}
