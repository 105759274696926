.container {
  @apply h-full w-full flex flex-col flex-1 justify-center items-center;
}

.pageTitle {
  @apply text-4xl font-semibold;
}

.info {
  @apply text-lg font-normal mt-3;
}

.link {
  @apply text-lg underline text-zur-orange-100;
}
